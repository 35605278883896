<template>
  <ul class="w-full">
    <li
      v-for="(c, chauffeurIndex) in chauffeurs"
      :key="`${chauffeurIndex}-${c.id}-${c.chauffeur_id}`"
      class="flex flex-col items-start justify-center w-full" :class="listClass"
    >
      <div class="flex flex-col lg:flex-row lg:items-center justify-between lg:gap-2 w-full">
        <div class="flex flex-row items-center gap-2">
          <span>{{ store.getters.chauffeur(c.chauffeur_id)?.label }}</span>
          <template v-if="toonWagen">
            <small class="text-gray-500">(<WagenLijn class="inline" geenDataText="Geen wagen opgegeven" label="" :wagen="store.getters.wagen(c.wagen_id || -1)" />)</small>
          </template>
        </div>
      </div>

      <small v-if="toonOmzet" class="block flex-none">
        <span><b>Omzet</b>: &euro;&nbsp;{{ formatPrice(c.omzet / 100 * c.commisie) }}</span>
        <span class="text-gray-500 pl-1">({{ formatPrice(c.commisie) }}% van &euro;&nbsp;{{ formatPrice(c.omzet) }})</span>
      </small>

      <LabelShiftUren
        v-if="toonShiften"
        class="flex-wrap"
        label-class="w-8"
        alerts
        compact
        :start="c?.shift_start || null"
        :aangekomen="c?.shift_aangekomen || null"
        :klant_ingestapt="c?.shift_klant_ingestapt || null"
        :klant_afgezet="c?.shift_klant_afgezet || null"
        :einde="c?.shift_einde || null"
      />
    </li>
    <li v-if="!chauffeurs.length" class="text-gray-400">
      Nog geen chauffeurs ingepland
    </li>
  </ul>
</template>

<script setup>
import { formatPrice } from '@/functions/formatNumber'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

const LabelShiftUren = defineAsyncComponent(() => import('@/components/UI/Labels/ShiftUren.vue'))
const WagenLijn = defineAsyncComponent(() => import('@/components/Table/Planning/WagenLijn.vue'))

defineProps({
  toonWagen: Boolean,
  toonOmzet: Boolean,
  toonShiften: Boolean,
  listClass: String,
  chauffeurs: {
    type: Array,
    required: true,
    default() {
      return []
    },
  },
})

const store = useStore()
</script>
