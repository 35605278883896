<template>
  <div v-if="betaling">
    <div>
      &euro;&nbsp;{{ formatPrice(betaling.bedrag) }} &mdash;
      <span class="text-gray-400 font-bold">{{ betaling.mollie_id ? 'MOLLIE' : capitalize(betaling.methode) }}</span>
      <ExternalLink v-if="store.getters.hasKantoorPermission && betaling.mollie_id" :href="`https://www.mollie.com/dashboard/payments/${betaling.mollie_id}`" class="ml-2">
        <i class="fas fa-external-link-alt"></i>
      </ExternalLink>
    </div>
    <div>{{ dateStringLocale(betaling.date) }} &mdash; <span class="text-gray-400 font-bold">{{ store.getters.chauffeur(betaling.user_id)?.label }}</span></div>
    <ButtonAlertOpmerking v-if="betaling.opmerking" class="my-2" :opmerking="betaling.opmerking" />
    <small v-if="store.getters.hasKantoorPermission" class="m-0 p-0 block">
      <span v-if="betaling.date_terugbetaling">
        Terugbetaling &mdash;
        &euro;&nbsp;{{ formatPrice(betaling.bedrag_terugbetaald) }} &mdash; {{ dateStringLocale(betaling.date_terugbetaling) }}
      </span>
      <span v-else class="text-gray-400 font-bold italic pl-2">Geen terugbetaling uitgevoerd</span>
    </small>
    <div class="flex gap-2 mt-2" v-if="store.getters.hasMasterPermission">
      <button class="btn warning small" @click="onHandleEdit(betaling)">
        <i class="fas fa-edit text-sm"></i> Bewerken
      </button>
      <button class="btn error small" @click="onHandleVerwijder(betaling)">
        <i class="fas fa-trash text-sm"></i> Verwijderen
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

import { dateStringLocale } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { capitalize } from '@/functions/formatText'
import useModal from '@/hooks/useModal'
import useApi from '@/hooks/useApi'
import ExternalLink from '@/components/UI/ExternalLink.vue'

const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

defineProps({
  betaling: {
    type: Object,
  },
})

const emit = defineEmits(['update:betaling', 'delete'])

const store = useStore()
const api = useApi()

const [openFixedModal] = useModal({ fixed: true })

const onHandleEdit = (betaling) => {
  const callback = () => emit('update:betaling', betaling)
  openFixedModal('FORM_BETALING', { modelValue: betaling, callback })
}

const onHandleVerwijder = (betaling) => {
  const callback = () => {
    api('BETALING_DELETE', { id: betaling.id }).then(() => {
      emit('delete', betaling)
    })
  }
  openFixedModal('BEVESTIG_ACTIE', { message: 'Betaling verwijderen?', callback })
}
</script>
